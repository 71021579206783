import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import A2201 from '../views/A2201.vue'
import A2202 from '../views/A2202.vue'
import Slfw from '../views/Slfw2023.vue'
import Xlanji from '../views/Xlanji2023.vue'
import Zhizhi from '../views/Zhizhi2023.vue'
import Huatongyu from '../views/Huatongyu2024.vue'
import Xfujun from '../views/Xfujun2024.vue'
import Jishizi from '../views/Jishizi2024.vue'

const routes = [
  {
    path: '/:code?',
    name: 'Index',
    meta: {
      title: '防伪码查询',
      keepAlive: true,
      footer: true,
      backgroundColor: '#fff'
    },
    component: Index
  },
  {
    path: '/A2201/:code?',
    name: 'A2201',
    meta: {
      title: '防伪码查询',
      keepAlive: true,
      footer: true,
      backgroundColor: '#fff'
    },
    component: A2201
  },
  {
    path: '/a2202/:code?',
    name: 'A2202',
    meta: {
      title: '防伪码查询',
      keepAlive: true,
      footer: true,
      backgroundColor: '#fff'
    },
    component: A2202
  },
  {
    path: '/slfw/:code?',
    name: 'Slfw',
    meta: {
      title: '舒龙生物防伪查询',
      keepAlive: true,
      footer: true,
      backgroundColor: '#fff'
    },
    component: Slfw
  },
  {
    path: '/xiulanji/:code?',
    name: 'Xiulanji',
    meta: {
      title: '秀兰集防伪查询',
      keepAlive: true,
      footer: true,
      backgroundColor: '#fff'
    },
    component: Xlanji
  },
  {
    path: '/zhizhi/:code?',
    name: 'Zhizhi',
    meta: {
      title: '知至防伪查询',
      keepAlive: true,
      footer: true,
      backgroundColor: '#fff'
    },
    component: Zhizhi
  },
  {
    path: '/huatyu/:code?',
    name: 'Huatongyu2024',
    meta: {
      title: '花桐语防伪查询',
      keepAlive: true,
      footer: true,
      backgroundColor: '#fff'
    },
    component: Huatongyu
  },
  {
    path: '/xfujun/:code?',
    name: 'Huatongyu',
    meta: {
      title: '仙福君防伪查询',
      keepAlive: true,
      footer: true,
      backgroundColor: '#fff'
    },
    component: Xfujun
  },
  {
    path: '/jsz/:code?',
    name: 'Jishizi',
    meta: {
      title: '吉食滋防伪查询',
      keepAlive: true,
      footer: true,
      backgroundColor: '#fff'
    },
    component: Jishizi
  },

]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes
})

export default router
